import React from 'react';
import '../../App.css';
function SignUp() {
    return (
        <h1 className="sign-up">
            Sign-UP

        </h1>
    )
}

export default SignUp;
