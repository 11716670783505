import React from 'react'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import './AboutUs.css'
import Footer from '../footer/Footer'

function AboutUs() {
    return (
        <>
            <Card className="about-us">
                <Card.Header>Company Profile</Card.Header>
                <Card.Body>
                    <Card.Title></Card.Title>
                    <Card.Text>
                        TELE Business Solutions is a Company that cares about you as a Customer or Partner. We provide you with the Best Quality Products and Services and reward you for giving us the opportunity to serve you. We provide a vast variety of Services and Products that save you time and money trying to get these from various other sources. Whether you are shopping for Residential or Commercial Services and Products, whether you are looking for Business Opportunities or you are looking for some Charitable Solutions, our Expert Consultants are ready to assist you. TDZonline Communications is our Flag Ship business Solution for Cutting edge Technology products and Services.
                    </Card.Text>
                    <Button variant="primary">contact</Button>
                </Card.Body>
            </Card>
            <br/>
            <Footer />
        </>
    )
}

export default AboutUs
