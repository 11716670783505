

import React from 'react';
import { Link } from 'react-router-dom';

function CardItem(props) {
    return (
        <>
            <li className="cards__item">
                <Link class="cards__item__link" to={props.path}>
                    <figure class="cards__item__pic-wrap" data-category={props.label}>
                        <img src={props.src} class="cards__item__img" alt="Travel" />
                    </figure>
                    <div class="cards__item__info">
                        <h5 class="cards__item__text">
                            {props.text}
                        </h5>

                    </div>
                </Link>

            </li>
        </>
    )
}

export default CardItem
