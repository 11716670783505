import React from 'react';
import '../../App.css';

function Services() {
    return (
        <h1 className="services">
            SERVICES
        </h1>
    )
}

export default Services
