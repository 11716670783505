import './App.css';
import Navbar from './components/navbar/Nabbar';
//import { MultiStepForm } from './components/multisectionform/MultiStepForm'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import Home from './components/home/Home';
import Services from './components/services/Services';
import Products from './components/products/Products';
import SignUp from './components/sign-up/SignUp';
import AboutUs from './components/about-us/AboutUs';

function App() {
  return (
    <div>
      <Router>
        <Navbar />
        <switch>
          <Route path="/" exact component={Home} />
          <Route path="/tdzonline/" component={Home} />
          <Route path="/services" component={Services} />
          <Route path="/products" component={Products} />
          <Route path="/sign-up" component={SignUp} />
          <Route path="/about-us" component={AboutUs} />
        </switch>
      </Router>
    </div>
  );
}

export default App;
