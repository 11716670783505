import React from 'react'
import CardItem from './CardItem';
import './Card.css';
import img9 from '../../img-css/img-9.jpg';
import img2 from '../../img-css/img-2.jpg';
import img3 from '../../img-css/img-3.jpg';
import img4 from '../../img-css/img-4.jpg';
import img8 from '../../img-css/img-8.jpg';
import img1 from '../../img-css/img-1.jpg'

function Card() {
    return (
        <div className="cards">
            <h1>Our offers</h1>
            <div class="cards__container">
                <div class="cards__wraper">
                    <ul class="cards__items">
                        <CardItem src={img9} text="Culture" path="/service1" label="read.." />
                        <CardItem src={img2} text="Information technology" path="/service2" label="read.." />
                    </ul>
                    <ul class="cards__items">
                        <CardItem src={img1} text="Bussness oportunities" path="/products" label="read.." />
                        <CardItem src={img2} text="Sport& leisure" path="/service4" label="read.." />
                    </ul>
                    <ul class="cards__items">
                        <CardItem src={img3} text="health and wealthness" path="/service5" label="read.." />
                        <CardItem src={img4} text="Communication" path="/service6" label="read.." />

                        <CardItem src={img8} text="Translation& interpretation" path="/service7" label="read.." />

                    </ul>

                    <ul class="cards__items">
                        <CardItem src={img3} text="Legal services" path="/service8" label="read.." />
                        <CardItem src={img4} text="Financial service" path="/service9" label="read.." />

                        <CardItem src={img8} text="NGO&philantropy" path="/service10" label="read.." />

                    </ul>
                </div>
            </div>

        </div>
    )
}

export default Card
