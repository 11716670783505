import { Link } from 'react-router-dom';
import './Button.css';


export const Button = ({ children, onClick, buttonSize, buttonStyle, type }) => {
    const STYLES = ['btn--primary', 'btn--outline'];
    const SIZES = ['btn--medium', 'btn--large'];
    const checkbuttonStyle = STYLES.includes(buttonStyle) ? buttonStyle : STYLES[0];
    const checkbuttonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0]
    return (
        <Link to="sign-up" className="btn-mobile">
            <button type={type} className={`btn ${checkbuttonStyle} ${checkbuttonSize}`} >
                {children}
            </button>

        </Link >
    )

}
