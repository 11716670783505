

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '../button/Button';
import './Navbar.css';

function Nabbar() {
    const [click, setClick] = useState(false);
    const [button, setButton] = useState(true);
    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);
    const showButton = () => {
        if (window.innerWidth <= 960) {
            setButton(false);
        } else {
            setButton(true);
        }
    }

    window.addEventListener('resize', showButton);

    useEffect(() => {
        showButton();
    }, []);

    return (

        <nav className="navbar">

            <div className="navbar-container">
                <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>TDZonline<i class="fab fa-typo3" /></Link>

            </div>
            <div className="menu-icon" onClick={handleClick}>
                <i className={click ? 'fas fa-times' : 'fas fa-bars'}></i>
            </div>
            <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                <li className="nav-item">
                    <Link className="nav-links" to="/" onClick={closeMobileMenu} >Home</Link>

                </li>
                
                <li className="nav-item">
                    <Link className="nav-links" to="/products" onClick={closeMobileMenu} >Products</Link>

                </li>
                <li className="nav-item">
                    <Link className="nav-links" to="/about-us" onClick={closeMobileMenu} >About us</Link>

                </li>




            </ul>
            {button && <Button buttonStyle="btn--outline">sign-in</Button>}



        </nav>



    )


}

export default Nabbar
