import React from 'react';
import './Main.css';
import videos from '../../videos/video-2.mp4';


function Main() {
    return (
        <div className="main-container">
            <video src={videos} autoPlay loop muted></video>
            <h1>BUSSNESS PLACE</h1>
            


        </div>
    )
}

export default Main
