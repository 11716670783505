
import React from 'react';
import Card from '../cards/Cards';
import Footer from '../footer/Footer';
//import '../../App.css'

import Main from '../main/Main';
function Home() {
    return (
        <>
            < Main />
            <Card />
            <Footer />

        </>
    )
}

export default Home;