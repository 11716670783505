import React from 'react';
import '../../App.css';
import Footer from '../footer/Footer'
import Card from 'react-bootstrap/Card'
import { Link } from 'react-router-dom';
function Products() {
    return (
        <>
     
        
        <Card className="about-us">
                <Card.Header>Christal Beauty US</Card.Header>
                <Card.Body>
                    <Card.Title></Card.Title>
                    <Card.Text>
                    Discover the beauty of nature with our all-natural products. Embrace your authentic self while caring for your skin and the planet. Experience the power of botanical goodness today!

                    With love, The Christal Beauty US Team.
                   <br/>
                   website: <Link className="nav-links0" to="/products">https://www.christalbeautyus.com/</Link>

               
                    </Card.Text>
                   
                </Card.Body>
            </Card>
            <Card className="about-us">
                <Card.Header>Company Products</Card.Header>
                <Card.Body>
                    <Card.Title></Card.Title>
                    <Card.Text>
                    Discover the beauty of nature with our all-natural products. Embrace your authentic self while caring for your skin and the planet. Experience the power of botanical goodness today!

With love, The Christal Beauty US Team
                    </Card.Text>
                   
                </Card.Body>
            </Card>
            <br/>
            <Footer />
         </>
    )
}

export default Products
