import React from 'react'
import { Link } from 'react-router-dom'
import Button from 'react-bootstrap/Button'
import './Footer.css'

function Footer() {
    return (
        <div className="footer-container">
            <section className="footer-subscription">
                <p className="footer-subscription-heading">
                    Join the adventure new letter to receive our best deals
                </p>
                <p class="footer-subscription-text"> You can unsubscribe at any time</p>
                <div class="input-areas">
                    <form action="#">
                        <input type="email" name="email" class="footer-input" placeholder="your email address" />
                        <Button buttonStyle="btn-outline">Subscribe</Button>
                    </form>
                </div>

            </section>
            <div class="footer-links">
                <div class="footer-link-wrapper">
                    <div class="footer-link-items">
                        <h2>About us</h2>
                        <Link to="/sign-up">Testimonials</Link>
                        <Link to="/sign-up">Careers</Link>
                        <Link to="/sign-up">Terms of services</Link>
                    </div>
                    <div class="footer-link-items">
                        <h2>Contact us </h2>
                        <Link to="/sign-up">Contact</Link>
                        <Link to="/sign-up">Support</Link>
                        <Link to="/sign-up">Sponsorship</Link>
                    </div>
                </div>
                <div class="footer-link-wrapper">
                    <div class="footer-link-items">
                        <h2>Video</h2>
                        <Link to="/sign-up">Submit video</Link>
                        <Link to="/sign-up">Ambassador</Link>
                    </div>

                    <div class="footer-link-items">
                        <h2>Social </h2>
                        <Link to="/sign-up">Instagram</Link>
                        <Link to="/sign-up">LinkedIn</Link>
                        <Link to="/sign-up">Facebook</Link>
                   
                    </div>
                </div>

            </div>
            <section class="social-media">
                <div class="social-media-wrap">
                    <div class="footer-logo">
                        <Link to="/" className="social-logo">TDZ <i class="fab fa-typo3"></i></Link>
                    </div>
                    <small class="website-rights">Copyright by TMZ 2023. All right reserve</small>
                    <div class="social-icons">
                        <Link class="social-icon-link facebook" target="_blank" aria-label="Facebook" to="/"><i class="fab fa-facebook-f"></i>
                        </Link>

                        <Link class="social-icon-link instagram" target="_blank" aria-label="Instagram" to="/"><i class="fab fa-instagram"></i>
                        </Link>

                        <Link class="social-icon-link twitter" target="_blank" aria-label="Twitter" to="/"><i class="fab fa-twitter"></i>
                        </Link>

                        <Link class="social-icon-link linkedin" target="_blank" aria-label="LinkedIn" to="/"><i class="fab fa-linkedin"></i>
                        </Link>
                        <Link class="social-icon-link youtube" target="_blank" aria-label="Youtube" to="/"><i class="fab fa-youtube"></i>
                        </Link>


                    </div>
                </div>
            </section>
        </div>
    )
}

export default Footer
